import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import {
  TournamentsBannersList as TournamentsBannersListCore,
  FormattedTag,
  RouteLink,
} from 'core/components';
import {
  isEmptyOrNil, getStaticUrl, getEnv, convertStringifiedBoolean
} from 'core/helpers';
import {
  withLocale,
  withCoreComponent,
  withImagesData
} from 'core/hocs';
import { withPixelRatio } from 'hocs/with-pixel-ratio';
import { TOURNAMENT_STATUSES } from 'core/constants';
import { Carousel } from 'components/carousel/carousel';

import { Tournament } from './tournament/tournament';
import { IMAGES_DATA_CATEGORIES } from '../../constants';

import './promo-tournaments.scss';

const { ACTIVE, PENDING, AWARDED } = TOURNAMENT_STATUSES;
const { TOURNAMENT } = IMAGES_DATA_CATEGORIES;

const responsiveSlidesToShow = [
  {
    breakpoint: 719,
    settings: {
      arrows: false,
      slidesToShow: 1.03
    }
  },
  {
    breakpoint: 959,
    settings: {
      arrows: false,
      slidesToShow: 1
    }
  },
  {
    breakpoint: 1279,
    settings: {
      arrows: false,
      slidesToShow: 1
    }
  },
  {
    breakpoint: 9999,
    settings: {
      arrows: true,
      slidesToShow: 1
    }
  },
];

const staticUrl = getStaticUrl();

export class PromoTournamentsUI extends Component {
  static propTypes = {
    items: PropTypes.shape(),
    // classNameTitle: PropTypes.string,
    locale: PropTypes.string.isRequired,
    classNameTournament: PropTypes.string,
    isOnlySliderType: PropTypes.bool,
    isOnlyCardType: PropTypes.bool,
    isTournamentsPage: PropTypes.bool,
    isHomePage: PropTypes.bool,
    imagesData: PropTypes.shape().isRequired,
    pixelRatio: PropTypes.string.isRequired,
    // tournamentsIsInProgress: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    items: {},
    // classNameTitle: null,
    classNameTournament: null,
    isOnlySliderType: false,
    isOnlyCardType: false,
    isTournamentsPage: false,
    isHomePage: false,
  };

  state = {
    activeNavLink: ACTIVE,
  };

  handleNavLinkClick = (e) => {
    const {
      dataset: {
        type,
      }
    } = e.currentTarget || {};

    this.setState({ activeNavLink: type });

    const tournamentHeader = document.getElementById(type);

    if (tournamentHeader) {
      const isFirstElement = type === ACTIVE;
      const headerHeight = 40 - 1;
      const yCoords = isFirstElement ? tournamentHeader.offsetTop + headerHeight : tournamentHeader.offsetTop;

      window.scrollTo(0, yCoords);
    }
  };

  createTournamentsNavLinks = (activeNavLink) => {
    const { items } = this.props;

    const targetItems = {
      [ACTIVE]: items.ACTIVE,
      [PENDING]: items.PENDING,
      [AWARDED]: items.AWARDED,
    };

    const navItems = R.compose(
      R.values,
      R.mapObjIndexed((_, status) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          key={status}
          // href={`#${status}`}
          data-type={status}
          className={classNames('promo-tournaments-nav-bar-link d-inline-block position-relative text-center font-weight-semi-bold text-nowrap px-2', {
            'promo-tournaments-nav-bar-link-active text-gray-40': status === activeNavLink,
            'text-gray-20': status !== activeNavLink,
          })}
          role="button"
          tabIndex="0"
          onClick={this.handleNavLinkClick}
          onKeyPress={this.handleNavLinkClick}
        >
          <FormattedTag
            id={`promo.tournaments.category-title.${(status || '').toLowerCase()}`}
            className="position-relative px-1 px-md-1_75"
          />
        </a>
      )),
      R.reject(isEmptyOrNil)
    )(targetItems);

    return !isEmptyOrNil(navItems) ? (
      <div
        className="promo-tournaments-nav-bar d-flex justify-content-sm-center align-items-center flex-nowrap bg-black mb-2_5 mb-md-4 mx-n2 mx-sm-n2_5 mx-lg-n5"
      >
        {navItems}
      </div>
    ) : null;
  };

  createTournamentComponents = () => {
    const {
      items,
      classNameTournament,
      isTournamentsPage,
      imagesData,
    } = this.props;

    const targetItems = {
      [ACTIVE]: items.ACTIVE,
      [PENDING]: items.PENDING,
      [AWARDED]: items.AWARDED,
    };

    const isShowAllTournaments = convertStringifiedBoolean(getEnv('IS_SHOW_ALL_TOURNAMENTS'));
    const imagesDataItems = R.propOr([], TOURNAMENT)(imagesData || {});

    if (isShowAllTournaments) {
      return R.compose(
        R.values,
        R.mapObjIndexed((tournamentsIds, status) => (
          <div key={status}>
            {isTournamentsPage && (
              <h2 id={status} className="promo-tournament-category-title text-gray-40 mb-1 mb-md-2">
                <FormattedTag
                  id={`promo.tournaments.category-title.${(status || '').toLowerCase()}`}
                />
              </h2>
            )}

            {R.map(id => (
              <Tournament
                coreProps={{ id }}
                status={status}
                className={classNames(classNameTournament)}
                imagesDataItems={imagesDataItems}
              />
            ))(tournamentsIds)}
          </div>
        )),
        R.reject(isEmptyOrNil)
      )(targetItems);
    }

    const tournaments = R.compose(
      R.values,
      R.mapObjIndexed((id, status) => (
        <div key={`${status}_${id}`}>
          {isTournamentsPage && (
            <h2 id={status} className="promo-tournament-category-title text-gray-40 mb-1 mb-md-2">
              <FormattedTag
                id={`promo.tournaments.category-title.${(status || '').toLowerCase()}`}
              />
            </h2>
          )}

          <Tournament
            coreProps={{ id }}
            status={status}
            className={classNames(classNameTournament, {
              'mb-3_5 mb-md-5': isTournamentsPage,
            })}
            imagesDataItems={imagesDataItems}
          />
        </div>
      )),
      R.reject(isEmptyOrNil)
    )(targetItems);

    return tournaments;
  };

  render() {
    const {
      items,
      // classNameTitle,
      isOnlySliderType,
      isOnlyCardType,
      isTournamentsPage,
      isHomePage,
      pixelRatio,
      locale
      // tournamentsIsInProgress
    } = this.props;

    const { activeNavLink } = this.state;

    const IMAGE_DIR_PATH = `${staticUrl}/images/tournaments/background-empty-list`;

    // if (tournamentsIsInProgress) {
    //   return <div className="rounded mb-2 mb-sm-2_5 w-100 my-8" />;
    // }

    if (isEmptyOrNil(items)) {
      return (
        <div
          className="promo-tournaments-no-data my-2 position-relative w-100 d-flex align-items-center justify-content-center"
        >

          {/* DESKTOP */}
          <div
            style={{ backgroundImage: `url(${IMAGE_DIR_PATH}/image-${locale}${pixelRatio}.png)` }}
            className="position-absolute w-100 h-100 tournaments-empty-list-banner d-none d-md-block"
          />

          {/* TABLET */}
          <div
            style={{ backgroundImage: `url(${IMAGE_DIR_PATH}/image-${locale}-t${pixelRatio}.png)` }}
            className="position-absolute w-100 h-100 tournaments-empty-list-banner d-none d-sm-block d-md-none"
          />

          {/* MOBILE */}
          <div
            style={{ backgroundImage: `url(${IMAGE_DIR_PATH}/image-${locale}-m${pixelRatio}.png)` }}
            className="position-absolute w-100 h-100 tournaments-empty-list-banner d-block d-sm-none"
          />

          {/* <FormattedTag */}
          {/* tag="h2" */}
          {/* id="tournaments.no-data" */}
          {/* className="text-white text-center my-0 mx-1 position-relative tournaments-empty-list-text" */}
          {/* /> */}
        </div>
      );
    }

    const tournamentComponents = this.createTournamentComponents();
    const navLinksComponent = isTournamentsPage ? this.createTournamentsNavLinks(activeNavLink) : null;

    return (
      <>
        {navLinksComponent}

        <div
          className={classNames('promo-tournaments', {
            'position-relative mb-1_75 mb-md-2_5': isHomePage
          })}
        >
          {(isHomePage || (!isHomePage && !isOnlyCardType)) && (
            <div className="d-flex align-items-center justify-content-between mb-1_75 mb-md-2_5">
              <div className="d-flex align-items-center">
                <FormattedTag
                  id="tournaments"
                  className="ml-1 text-large font-weight-bold letter-spacing-extra"
                />
              </div>

              <Button
                tag={RouteLink}
                to="/tournaments"
                color="link"
                className="py-0_25 px-1 text-capitalize mr-md-12"
              >
                <FormattedTag id="see-all" />
              </Button>
            </div>
          )}

          {!isEmptyOrNil(tournamentComponents) && !isOnlyCardType && (
            <Carousel
              settings={{
                arrows: false,
                slidesToShow: 1,
                responsive: responsiveSlidesToShow,
              }}
              className={classNames('tournament-carousel', { 'd-sm-none': !isOnlySliderType })}
            >
              {tournamentComponents.map(tournament => (
                <div
                  key={tournament.key}
                  className="tournament-carousel-item"
                >
                  {tournament}
                </div>
              ))}
            </Carousel>
          )}

          {isOnlyCardType && tournamentComponents}
        </div>
      </>
    );
  }
}

export const PromoTournaments = withCoreComponent(
  TournamentsBannersListCore,
  withLocale(
    withImagesData(
      withPixelRatio(PromoTournamentsUI)
    )
  )
);
