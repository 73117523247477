/* eslint-disable react/jsx-curly-newline */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import Countdown from 'react-countdown-now';
import { TournamentItem as TournamentItemCore, RouteLink, FormattedTag } from 'core/components';
import { withCoreComponent, withUser } from 'core/hocs';
import { getIntlKeyByEnv, getStaticUrl, isEmptyOrNil } from 'core/helpers';
import { TOURNAMENT_STATUSES, USER_FIELDS } from 'core/constants';

import { TournamentTable } from '../tournament-table/tournament-table';
import { withPixelRatio } from '../../../hocs/with-pixel-ratio';
import { createBasicPath } from '../../../helpers/check-pixel-ratio';

import './tournament.scss';

const staticUrl = getStaticUrl();

const {
  ACTIVE,
  PENDING,
  AWARDED,
} = TOURNAMENT_STATUSES;

const TOURNAMENT_COUNTDOWN_CLASSES = 'tournament-countdown-item d-flex flex-column align-items-center mx-2 mt-1_25';

const CountdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
}) => (
  <div className="tournament-countdown d-flex align-items-center text-white position-relative">
    {/* <div className={classNames(TOURNAMENT_COUNTDOWN_CLASSES)}>
      <div className="tournament-countdown-item-digits mb-0_5">
        {days}
      </div>

      <div className="tournament-countdown-item-text h5 font-weight-normal text-capitalize">
        <FormattedMessage
          id="countdown.plural-data.days"
          values={{ value: days }}
        />
      </div>
    </div>

    <span className="tournament-countdown-item-divider">:</span> */}

    <div className={classNames(TOURNAMENT_COUNTDOWN_CLASSES)}>
      <div className="tournament-countdown-item-digits mb-0_5">
        {(days * 24) + hours}
      </div>

      <div className="tournament-countdown-item-text h5 font-weight-normal text-capitalize">
        <FormattedMessage
          id="countdown.plural-data.hour"
          values={{ value: (days * 24) + hours }}
        />
      </div>
    </div>

    <span className="tournament-countdown-item-divider">:</span>

    <div className={classNames(TOURNAMENT_COUNTDOWN_CLASSES)}>
      <div className="tournament-countdown-item-digits mb-0_5">
        {minutes}
      </div>

      <div className="tournament-countdown-item-text h5 font-weight-normal text-capitalize">
        <FormattedMessage
          id="countdown.plural-data.minute"
          values={{ value: minutes }}
        />
      </div>
    </div>

    <span className="tournament-countdown-item-divider">:</span>

    <div className={classNames(TOURNAMENT_COUNTDOWN_CLASSES)}>
      <div className="tournament-countdown-item-digits mb-0_5">
        {seconds}
      </div>

      <div className="tournament-countdown-item-text h5 font-weight-normal text-capitalize">
        <FormattedMessage
          id="countdown.plural-data.second"
          values={{ value: seconds }}
        />
      </div>
    </div>
  </div>
);

CountdownRenderer.propTypes = {
  days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  seconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export class TournamentUI extends Component {
  getImageUrl = (isMobile = false) => {
    const {
      item: { id },
      pixelRatio,
      imagesDataItems,
    } = this.props;

    let result;
    const defaultBackgroundUrl = `${staticUrl}/images/tournament/banner/default-bg.jpg`;

    const backgroundUrl = R.compose(
      R.propOr(defaultBackgroundUrl, 'imgUrl'),
      R.find(
        R.propEq('entityId', id.toString()),
      ),
    )(imagesDataItems || []);

    const basicBackgroundPath = createBasicPath(backgroundUrl) || [];

    if (isMobile) {
      result = !isEmptyOrNil(basicBackgroundPath)
        ? `url("${basicBackgroundPath[0]}-mobile${pixelRatio}${basicBackgroundPath[1]}"), url("${basicBackgroundPath[0]}-mobile${basicBackgroundPath[1]}")`
        : '';
    } else {
      result = !isEmptyOrNil(basicBackgroundPath)
        ? `url("${basicBackgroundPath[0]}${pixelRatio}${basicBackgroundPath[1]}"), url("${backgroundUrl}")`
        : '';
    }

    return result;
  };

  render() {
    const {
      item: {
        slugUrl,
        tournamentRankingList,
        tournamentAwards,
        endDate,
        startDate,
        id
      },
      status,
      isUserLoggedIn,
      className,
    } = this.props;

    // const backgroundImageMobile = this.getImageUrl(true);
    const backgroundImageDesktop = this.getImageUrl();

    const timeTextIntlKeyParts = {
      [ACTIVE]: 'ends-in',
      [PENDING]: 'starts-in',
      [AWARDED]: 'finished',
    };

    const buttonTextIntlKeys = {
      [ACTIVE]: 'play-now',
      [PENDING]: 'promo.tournament.details',
      [AWARDED]: 'promo.tournament.see-result',
    };

    const timeData = (
      <>
        {status === ACTIVE && (
          <FormattedTag
            id="tournaments.tournament-info.live"
            className="tournament-card-live-indicator position-absolute text-base text-lowercase text-white"
          />
        )}

        <div className="tournament-card-time-data mb-3 mb-sm-4 d-flex flex-column align-items-center">
          <FormattedTag
            id={`tournaments.tournament-info.${timeTextIntlKeyParts[status]}`}
            className="tournament-card-time-data-header caption font-weight-semi-bold text-white text-uppercase position-relative"
          />

          {[ACTIVE, PENDING].includes(status) && (
            <Countdown
              date={status === ACTIVE ? endDate : startDate}
              renderer={CountdownRenderer}
            />
          )}
        </div>
      </>
    );

    return (
      <div
        className={classNames(
          'tournament-card d-flex flex-column flex-sm-row justify-content-sm-between overflow-hidden bg-black mb-2',
          className
        )}
      >
        {/* Mobile background */}
        <div
          className="tournament-card-background-image d-flex d-sm-none flex-column align-items-center justify-content-end position-relative"
          style={{ ...(!!backgroundImageDesktop && { backgroundImage: backgroundImageDesktop }) }}
        >
          {timeData}
        </div>

        {/* Desktop background */}
        <div
          className="tournament-card-background-image d-none d-sm-flex flex-column align-items-center justify-content-end position-relative"
          style={{ ...(!!backgroundImageDesktop && { backgroundImage: backgroundImageDesktop }) }}
        >
          {timeData}
        </div>

        <div
          className="tournament-card-desc d-flex flex-column pt-2 px-2 pb-1 pt-sm-4 px-sm-4 pb-sm-2_5"
        >
          <FormattedTag
            id={getIntlKeyByEnv(`tournament.title.${id}`)}
            tag="div"
            className="tournament-title text-base mb-0_5"
            isHtml
          />

          <FormattedTag
            id={getIntlKeyByEnv(`tournament.prize.${id}`)}
            tag="div"
            className="tournament-desc font-weight-strong"
            isHtml
          />

          <Button
            tag={RouteLink}
            to={`/tournament/${slugUrl}`}
            className="w-100 mt-2_5 mb-3_5 mt-md-2_75 mb-3"
            color="primary"
          >
            <FormattedMessage id={buttonTextIntlKeys[status]} />
          </Button>

          <TournamentTable
            tournamentAwards={tournamentAwards}
            rankings={tournamentRankingList}
            isUserLoggedIn={isUserLoggedIn}
            status={status}
            slugUrl={slugUrl}
          />
        </div>
      </div>
    );
  }
}

TournamentUI.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.shape({
      prize: PropTypes.string,
      background: PropTypes.string,
      backgroundBanner: PropTypes.string,
      image: PropTypes.string
    }),
    slugUrl: PropTypes.string.isRequired,
    tournamentRankingList: PropTypes.arrayOf(PropTypes.shape()),
    tournamentAwards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    startDate: PropTypes.number.isRequired,
    endDate: PropTypes.number.isRequired
  }).isRequired,
  status: PropTypes.string.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  className: PropTypes.string,
  pixelRatio: PropTypes.string.isRequired,
  imagesDataItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

TournamentUI.defaultProps = {
  className: null
};

export const Tournament = withCoreComponent(
  TournamentItemCore,
  withPixelRatio(
    withUser(TournamentUI, [
      USER_FIELDS.IS_USER_LOGGED_IN,
    ]),
  ),
);
