import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { FormattedTag, RouteLink } from 'core/components';
import { withLocale } from 'core/hocs';
import { isEmptyOrNil, createListOfTournamentAwards, getRemoteConfig } from 'core/helpers';
import { TOURNAMENT_STATUSES } from 'core/constants';
import Button from 'reactstrap/lib/Button';
import { getUsernameFromEmail, setUsernameHidden } from '../../../helpers/username';
import { formatAmountWithCurrency, formatFloatAmountWithLocale } from '../../../helpers/numbers';
import { TOURNAMENTS_EMPTY_FIELDS } from '../../../constants';

import './tournament-table.scss';

const MAX_RANKING_ROWS = 3;

// const createAwardsString = award => award.length > 1 ? R.join(' + ', award) : award[0];

export const TournamentTableUI = ({
  rankings,
  tournamentAwards,
  status,
  locale,
  slugUrl,
}) => {
  const awards = createListOfTournamentAwards({ tournamentAwards });
  const isEmptyTable = status === TOURNAMENT_STATUSES.PENDING || isEmptyOrNil(rankings);

  const prizeAmountWithConversion = ({ points, currency }) => {
    const exchangeRate = getRemoteConfig(`LOYALTY_POINTS.EXCHANGE_RATES.${currency}`);

    return `${formatFloatAmountWithLocale(points, locale)} (${formatAmountWithCurrency({ amount: Math.floor(points / exchangeRate), currency, locale })})`;
  };

  return (
    <div className="tournament-table w-100">
      {!isEmptyOrNil(awards) && (
        <>
          <div className="d-flex justify-content-between align-items-center mb-1_5 mb-md-1_75">
            <FormattedTag
              id="promo.tournament.prizes"
              className="h4 font-weight-semi-bold"
            />

            <Button
              tag={RouteLink}
              to={`/tournament/${slugUrl}`}
              color="link"
              className="tournament-table-details-link p-0"
            >
              {status === TOURNAMENT_STATUSES.AWARDED ? (
                <FormattedTag id="promo.tournament.see-all-winners" />
              ) : (
                <FormattedTag id="promo.tournament.see-all-prizes" />
              )}
            </Button>
          </div>
        </>
      )}

      {(status === TOURNAMENT_STATUSES.ACTIVE || status === TOURNAMENT_STATUSES.AWARDED)
        && !isEmptyOrNil(awards)
        && !isEmptyOrNil(rankings)
        && R.take(MAX_RANKING_ROWS, awards)
          .map((award, idx) => (
            <div
              className="tournament-table-row d-flex align-items-center position-relative pb-1 mb-1"
              // eslint-disable-next-line react/no-array-index-key
              key={`${idx}_${award[0]}`}
            >
              <div className="place-column h4 font-weight-semi-bold d-flex justify-content-center align-items-center flex-shrink-0 mr-1_25">
                <span className="line-height-1">
                  {R.inc(idx)}
                </span>
              </div>

              <div className="name-column d-flex flex-column flex-grow-1 overflow-hidden pr-0_5">
                <div className="h4 font-weight-semi-bold text-truncate">
                  {rankings[idx]
                    ? setUsernameHidden(getUsernameFromEmail(rankings[idx].userid))
                    : TOURNAMENTS_EMPTY_FIELDS.USER}
                </div>

                <FormattedTag
                  tag="div"
                  id={`promo.tournament.table.place-desc.${R.inc(idx)}`}
                  className="name-column-desc caption text-truncate text-gray-10"
                />
              </div>

              <div className="points-column h4 font-weight-semi-bold text-right text-nowrap flex-grow-1 flex-shrink-0">
                {rankings[idx]
                  ? prizeAmountWithConversion(rankings[idx])
                  : TOURNAMENTS_EMPTY_FIELDS.POINTS}
              </div>
            </div>
          ))}

      {isEmptyTable
        && !isEmptyOrNil(awards)
        && R.take(MAX_RANKING_ROWS, awards)
          .map((award, idx) => (
            <div
              className="tournament-table-row d-flex align-items-center position-relative pb-1 mb-1"
              // eslint-disable-next-line react/no-array-index-key
              key={`${idx}_${award[0]}`}
            >
              <div className="place-column h4 font-weight-semi-bold d-flex justify-content-center align-items-center flex-shrink-0 mr-1_25">
                <span className="line-height-1">
                  {R.inc(idx)}
                </span>
              </div>

              <div className="name-column d-flex flex-column flex-grow-1 overflow-hidden pr-0_5">
                <div className="h4 font-weight-semi-bold text-truncate">
                  {TOURNAMENTS_EMPTY_FIELDS.USER}
                </div>

                <FormattedTag
                  tag="div"
                  id={`promo.tournament.table.place-desc.${R.inc(idx)}`}
                  className="name-column-desc caption text-truncate text-gray-10"
                />
              </div>

              <div className="points-column h4 font-weight-semi-bold text-right text-nowrap flex-grow-1 flex-shrink-0">
                {rankings[idx]
                  ? prizeAmountWithConversion(rankings[idx])
                  : TOURNAMENTS_EMPTY_FIELDS.POINTS}
              </div>
            </div>
          ))}
    </div>
  );
};

TournamentTableUI.propTypes = {
  rankings: PropTypes.arrayOf(PropTypes.shape()),
  tournamentAwards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  status: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  slugUrl: PropTypes.string.isRequired,
};

TournamentTableUI.defaultProps = {
  rankings: null,
};

export const TournamentTable = withLocale(TournamentTableUI);
